import React from 'react';

function ArrowRight(props) {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 768 768" {...props}>
      <path d="M384 127.5l256.5 256.5-256.5 256.5-45-45 178.5-180h-390v-63h390l-178.5-180z" />
    </svg>
  );
}

export default ArrowRight;
